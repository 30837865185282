import { apiConfig } from "../helpers/api-config";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/*This file contains all functions related to the system data of the products */

const baseUrl = apiConfig + "/takeoff";

const getTakeoffList = (data) => {
  return fetchWrapper.post(baseUrl + "/GetTakeoffList", data);
};

const deleteTakeoff = (data) => {
  return fetchWrapper.post(baseUrl + "/DeleteTakeoff", data);
};

const getTakeoffSteps = (data) => {
  return fetchWrapper.post(baseUrl + "/GetTakeoffSteps", data);
};

const createTakeoff = (data) => {
  return fetchWrapper.post(baseUrl + "/CreateTakeoff", data);
};

const updateTakeoff = (takeoffID, data) => {
  return fetchWrapper.post(baseUrl + "/UpdateTakeoff/" + takeoffID, data);
};

const getTakeoffByID = (takeoffID) => {
  return fetchWrapper.get(baseUrl + "/GetTakeoffByID/" + takeoffID);
};

const getTakeoffByCompanyID = (companyID) => {
  return fetchWrapper.get(baseUrl + "/GetTakeoffsByCompanyID/" + companyID);
};

const GetTakeoffByAgreementPurchasing = (data) => {
  return fetchWrapper.post(baseUrl + "/GetTakeoffByAgreementPurchasing", data);
};

const getLinkedTakeoffsByID = (takeoffID) => {
  return fetchWrapper.get(baseUrl + "/GetLinkedTakeoffs/" + takeoffID);
};
const updateTakeoffLinkStatus = ( data) => {
  return fetchWrapper.post(baseUrl + "/UpdateTakeoffLinkStatus" , data);
};

export const takeoffServices = {
  getTakeoffList,
  deleteTakeoff,
  getTakeoffSteps,
  createTakeoff,
  updateTakeoff,
  getTakeoffByID,
  getTakeoffByCompanyID,
  GetTakeoffByAgreementPurchasing,
  getLinkedTakeoffsByID,
  updateTakeoffLinkStatus
};
